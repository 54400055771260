/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected institution.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass institution context to the rest of the app.
 */
import InstitutionsContext from '../../../contexts/data/InstitutionsContext';

/*
 * Used to load collaborating groups
 */
import useInstitutionsByIds from '../../../hooks/data/useInstitutionsByIds';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IInstitutionsByIdsResolverProps {
    masterGroupId?: number | null;
    collaboratingGroupId?: number | null;
    countryId?: number | null;

    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const InstitutionsByIdsResolver: React.FunctionComponent<IInstitutionsByIdsResolverProps> = ({
    masterGroupId,
    collaboratingGroupId,
    countryId,
    resolveBeforeLoad,
    children
}) => {
    const [institutions, loadState, actions] = useInstitutionsByIds(masterGroupId, collaboratingGroupId, countryId, true);

    const context = React.useMemo(() => {
        return {
            institutions,
            loadState,
            actions
        };
    }, [institutions, loadState, actions]);

    return (
        <InstitutionsContext.Provider value={context}>
            {
                !resolveBeforeLoad || (loadState.state !== RequestState.None && loadState.state !== RequestState.Pending) ?
                    children :
                    <RouteLoading />
            }
        </InstitutionsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default InstitutionsByIdsResolver;