/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the eoi dialog component
 * --------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { Button, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid, Link, Typography } from '@material-ui/core';

import { DateTime } from 'luxon';

import {
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
    FileUpload
} from '@ngt/opms';

import AlertTitle from '@material-ui/lab/AlertTitle';

import { KeyboardDatePicker } from '@material-ui/pickers';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { useEoi } from '../hooks/useEoi';
import { StatusTypeEnum } from '../api/dtos';
import { useEois } from '../hooks/useEois';
import { useParams } from 'react-router-dom';
import { PatientConsent } from '../api/patientConsent';
import { usePatientConsents } from '../hooks/usePatientConsents';
import EConsentContext from '../context/EConsentContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IEoiDialogProps {
    eoiId: number;
    mode: "face-to-face-consent" | "remote-consent" | "standard-consent" | "not-interested";
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitle: {
        color: theme.palette.primary.main
    },
    dialogContent: {
        padding: theme.spacing(0, 3),
        overflow: 'visible'
    },
    link: {
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const EoiDialog: React.FunctionComponent<IEoiDialogProps> = ({
    eoiId,
    mode,
    open,
    setOpen
}: IEoiDialogProps) => {
    const classes = useStyles();

    const contextEConsent = React.useContext(EConsentContext);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement?.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const { institutionCode } = useParams<Record<string, string>>();

    const [eois, eoisLoadState, eoisActions] = useEois(institutionCode);

    const [eoi, eoiActions, eoiLoadState] = useEoi(eoiId, true);

    const [patientConsents, patientConsentsLoadState, patientConsentsActions] = usePatientConsents(institutionCode);

    const [picfs, setPicfs] = React.useState<Dtos.Picf[]>([]);

    const [actionLoading, setActionLoading] = React.useState(false);

    const [fileUpload, setFileUpload] = React.useState<Dtos.ConsentFileUpload>();

    const [error, setError] = React.useState<string>();

    const dialogTitle = React.useMemo(() => {
        switch (mode) {
            case "face-to-face-consent": { return "Face-to-face Consent"; break; }
            case "remote-consent": { return "Remote Consent"; break; }
            case "standard-consent": { return "Standard Consent"; break; }
            case "not-interested": { return "Not Interested"; break; }
        }
    }, [mode]);

    const onNotInterestedClick = React.useCallback(() => {

        setActionLoading(true);

        client
            .post(new Dtos.EoiSaveStatus({
                id: eoi!.id,
                statusId: StatusTypeEnum.NotInterested
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Saved
                        </AlertTitle>
                        Changes were saved successfully.
                    </>,
                    { variant: 'success' }
                );
                eoisActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Saved
                        </AlertTitle>
                        An error occurred while trying to save the changes.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, eoi, eoisActions, enqueueSnackbar, setOpen, setActionLoading]);

    const onRemoteConsentClick = React.useCallback(() => {

        setActionLoading(true);

        const patientConsent = {
            eoiId: eoi?.id,
            dateStarted: DateTime.local().toUTC().toString(),
            dateNotificationSent: DateTime.local().toUTC().toString(),
            typeId: Dtos.ConsentTypeEnum.Remote,
            statusId: StatusTypeEnum.PatientSigning
        }

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: patientConsent as unknown as PatientConsent,
                emailRequired: true
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Started
                        </AlertTitle>
                        Remote consent has started successfully.
                    </>,
                    { variant: 'success' }
                );
                eoisActions.load();
                patientConsentsActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Started
                        </AlertTitle>
                        An error occurred while trying to start the remote consent process.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, eoi, eoisActions, enqueueSnackbar, patientConsentsActions, setActionLoading, setOpen]);

    const onFaceToFaceConsentClick = React.useCallback(() => {

        setActionLoading(true);

        const patientConsent = {
            eoiId: eoi?.id,
            dateStarted: DateTime.local().toUTC().toString(),
            typeId: Dtos.ConsentTypeEnum.FaceToFace,
            statusId: StatusTypeEnum.PatientSigning
        }

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: patientConsent as unknown as PatientConsent,
                emailRequired: false
            }))
            .then(response => {
                window.location.assign(contextEConsent.trialConsentPageUrl + response.patientConsent?.guid);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Started
                        </AlertTitle>
                        An error occurred while trying to start the face-to-face consent process.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, eoi, eoisActions, patientConsentsActions, enqueueSnackbar, setOpen, setActionLoading]);

    const [standardConsentDate,setStandardConsentDate] = React.useState<DateTime | null>(null);

    const onStandardConsentClick = React.useCallback(() => {

        setActionLoading(true);

        const patientConsent = {
            eoiId: eoi?.id,
            dateStarted: DateTime.local().toUTC().toString(),
            typeId: Dtos.ConsentTypeEnum.Standard,
            statusId: StatusTypeEnum.Completed,
            patientSigned: true,
            datePatientSigned: standardConsentDate?.toUTC().toString(),
            investigatorSigned: true,
            dateInvestigatorSigned: standardConsentDate?.toUTC().toString(),
            //documentUpload: fileUpload,
            picfs: picfs.map(picf => {
                var consentPicf = new Dtos.ConsentPicf();

                consentPicf.picfTypeId = picf.type.id;
                consentPicf.picfId = picf.picfId;

                return consentPicf;
            })
        }

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: patientConsent as unknown as PatientConsent,
                emailRequired: true
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Submitted
                        </AlertTitle>
                        Consent has been submitted successfully.
                    </>,
                    { variant: 'success' }
                );
                eoisActions.load();
                patientConsentsActions.load();
                setActionLoading(false);
                setOpen(false);
                setError(undefined);
                setFileUpload(undefined);
                setStandardConsentDate(null);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Submitted
                        </AlertTitle>
                        An error occurred while trying to submit the consent.
                    </>,
                    { variant: 'critical' }
                );
                setError(e.responseStatus.message);
                setActionLoading(false);
                setFileUpload(undefined);
                setStandardConsentDate(null);
            })
    }, [client, eoi, eoisActions, patientConsentsActions, picfs, enqueueSnackbar, setOpen, setActionLoading, fileUpload, setFileUpload, setError, standardConsentDate, setStandardConsentDate]);

    const picfDownloadLink = `/opms/picf/download/all/${eoi?.invitation?.institutionCode}`;

    const onPicfDownloadClick = React.useCallback(() => {
        setActionLoading(true);

        client
            .get(new Dtos.GetDownloadPicfs({
                instCode: eoi?.invitation?.institutionCode
            }))
            .then(response => {
                var link = document.createElement('a');
                link.href = picfDownloadLink
                link.click();
                link.remove();

                setActionLoading(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while trying to download the PICF file.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setFileUpload(undefined);
            });

    }, [client, enqueueSnackbar, eoi, setActionLoading, setFileUpload, picfDownloadLink]);

    React.useEffect(() => {
        if (eoi?.invitation?.institutionCode) {
            client
                .get(new Dtos.GetPicfs({
                    instCode: eoi?.invitation?.institutionCode
                }))
                .then(response => {
                    setPicfs(response.picfs)
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            There is a problem with the site PICFs. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                });
        }
    }, [client, enqueueSnackbar, eoi, setPicfs]);

    const dialogText: React.ReactNode = React.useMemo(() => {
        switch (mode) {
            case "face-to-face-consent": {
                return `Start the face-to-face consent process. The participant will have to complete the consent form.`;
                break;
            }
            case "remote-consent": {
                return `Start the remote consent process. The participant will receive an email to complete the consent form.`;
                break;
            }
            case "standard-consent": {
                return <>Download the consent form <Link className={classes.link} variant="body1" onClick={onPicfDownloadClick}>here</Link>.</>;
                break;
            }
            case "not-interested": {
                return `The participant is not interested to participate in the study.`;
                break;
            }
        }
    }, [mode, onPicfDownloadClick]);

    return (
        <>
            {
                !!eoi && <Dialog open={open} onClose={() => { setOpen(false); setFileUpload(undefined); }} aria-labelledby="eoi-dialog" maxWidth="sm">
                    <DialogTitle
                        id="eoi-dialog-title"
                        className={classes.dialogTitle}
                    >
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText>
                            {dialogText}
                        
                            {
                                mode === "standard-consent" && (
                                    <Grid
                                        container
                                        style={{ marginTop: "1rem" }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Typography style={{ paddingTop: '0.5rem' }}>Date consent completed</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                value={standardConsentDate}
                                                onChange={(date) => setStandardConsentDate(date)}
                                                clearable
                                                fullWidth
                                            />
                                            {
                                                error && (
                                                    <DialogContentText style={{ color: '#f44336', fontSize: '0.8rem', paddingTop: '0.5rem' }}>
                                                        {error}
                                                    </DialogContentText>
                                                )
                                            }

                                        
                                        </Grid>
                                        <div style={{ marginTop: '1.5rem' }}>Please ensure signed PICF has been filed in ISF for viewing at onsite monitoring.</div>
                                    </Grid>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ProgressButton
                            loading={actionLoading}
                            color="secondary"
                            onClick={() => { setOpen(false); setFileUpload(undefined); }}
                        >
                            Cancel
                        </ProgressButton>
                        {
                            mode === "face-to-face-consent" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onFaceToFaceConsentClick()}
                                disabled={false}
                            >
                                Start {dialogTitle}
                            </ProgressButton>
                        }

                        {
                            mode === "remote-consent" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onRemoteConsentClick()}
                                disabled={false}
                            >
                                Start {dialogTitle}
                            </ProgressButton>
                        }

                        {
                            mode === "standard-consent" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onStandardConsentClick()}
                                disabled={false}
                            >
                                Submit
                            </ProgressButton>
                        }

                        {
                            mode === "not-interested" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onNotInterestedClick()}
                                disabled={false}
                            >
                                {dialogTitle}
                            </ProgressButton>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default EoiDialog;