/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */
import { IOnlinePatientManagementExtension, OnlinePatientManagement } from '@ngt/opms';

import * as React from 'react';

import { Store, Middleware } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type form metadata
 */
import * as Dtos from './api/dtos';

/*
 * Used to register the invitations reducer;
 */
import registerInvitationsReducer from './store/invitations';

import EConsentContext, { IEConsentContext } from './context/EConsentContext';
import registerInvitationReducer from './store/invitation';
import registerEoisReducer from './store/eois';
import registerEoiReducer from './store/eoi';
import registerPatientConsentsReducer from './store/patientConsents';
import registerPatientConsentReducer from './store/patientConsent';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IEConsentExtensionReducerOptions {
    invitations?: boolean | null;
    invitation?: boolean | null;
    eois?: boolean | null;
    eoi?: boolean | null;
    patientConsents?: boolean | null;
    patientConsent?: boolean | null;
};

interface IEConsentExtensionStoreOptions {
    initialiseReducers?: boolean | null | IEConsentExtensionReducerOptions;
};

export interface IEConsentExtensionOptions {
    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    storeOptions?: IEConsentExtensionStoreOptions | null;
    trialInfoPageUrl: string,
    trialConsentPageUrl: string,
    trialConsentSignedPageUrl: string,
    noInviteOption?: boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class handles the global context used by the OPMS.
 */
export class EConsentExtension implements IOnlinePatientManagementExtension {
    private reducerOptions: boolean | IEConsentExtensionReducerOptions;
    public ProviderComponent: React.ComponentType<{
        children: React.ReactNode;
    }> | undefined;

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IEConsentExtensionOptions) {
        this.initialiseReducerOptions(options?.storeOptions?.initialiseReducers);
        this.initialiseProviderComponent(options);
    }

    private initialiseReducerOptions(reducerOptions?: boolean | IEConsentExtensionReducerOptions | null) {
        this.reducerOptions = reducerOptions ?? true;
    }

    private initialiseProviderComponent(options: IEConsentExtensionOptions) {
        const context: IEConsentContext = {
            trialInfoPageUrl: options.trialInfoPageUrl,
            trialConsentPageUrl: options.trialConsentPageUrl,
            trialConsentSignedPageUrl: options.trialConsentSignedPageUrl,
            noInviteOption: options.noInviteOption
        };

        this.ProviderComponent = ({ children }) => {
            return (
                <EConsentContext.Provider value={context}>
                    {children}
                </EConsentContext.Provider>
            );
        };
    }


    /**
     * This method initialises the Reducers in the Reducer Registry.
     * @param onlinePatientManagement Online Patient Management.
     */
    public initialiseReducers(onlinePatientManagement: OnlinePatientManagement) {
        // Check if reducer initialisation was requested to be skipped.
        if (this.reducerOptions === false) {
            return;
        }

        if (this.reducerOptions === true || this.reducerOptions?.invitations !== false) {
            // Register Authenticated User Reducer if requested.
            registerInvitationsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.invitation !== false) {
            // Register Authenticated User Reducer if requested.
            registerInvitationReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.eois !== false) {
            // Register Authenticated User Reducer if requested.
            registerEoisReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.eoi !== false) {
            // Register Authenticated User Reducer if requested.
            registerEoiReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.patientConsents !== false) {
            // Register Authenticated User Reducer if requested.
            registerPatientConsentsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }

        if (this.reducerOptions === true || this.reducerOptions?.patientConsent !== false) {
            // Register Authenticated User Reducer if requested.
            registerPatientConsentReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OnlinePatientManagement;

