/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the eoi collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Link } from 'react-router-dom';

import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { History as RouterHistory } from 'history';

import { Button, FormControl, InputLabel, Select, MenuItem, Grid, Tooltip, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, IconButton, IconButtonProps } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    ICollapsibleTableProps,
    IInstitution,
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
    useAuthenticatedUser
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import AlertTitle from '@material-ui/lab/AlertTitle';
/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Used for the face-to-face consent icon.
 */
import { faUserMd } from '@fortawesome/pro-duotone-svg-icons/faUserMd';
/**
 * Used for the remote consent icon.
 */
import { faCarAlt } from '@fortawesome/pro-duotone-svg-icons/faCarAlt';
/**
 * Used for the remote consent icon.
 */
import { faLaptopHouse } from '@fortawesome/pro-duotone-svg-icons/faLaptopHouse';
/**
 * Used for the standard consent icon.
 */
import { faFileMedicalAlt } from '@fortawesome/pro-duotone-svg-icons/faFileMedicalAlt';
/**
 * Used for the refuse consent icon.
 */
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { Eoi } from '../api/eoi';

import { useEois, IUseEoisActions } from '../hooks/useEois';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EoiDialog from './EoiDialog';
import { StatusTypeEnum } from '../api/dtos';
import { InvestigatorsContext } from './EConsentPage'

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface IEoiCollapsibleTableProps extends OmitData<ICollapsibleTableProps<Eoi>> {
    data?: Array<Eoi>;
    columns?: Array<Column<Eoi>>;
    title?: string;
    loading?: boolean;
}

interface IEoiButtonProps extends IconButtonProps {
    icon: IconProp;
    action: () => void;
    tooltipTitle?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    button: {
        padding: theme.spacing(0),
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.EConsentView,
    Permission.EConsentAdminister,
];


const useEoisColumns = (
    canAdministerEConsent: boolean,
    institutions: Array<IInstitution>,
    showInstitution: boolean,
    setEoidId: React.Dispatch<React.SetStateAction<number | undefined>>,
    setEoiDialogMode: React.Dispatch<React.SetStateAction<string | undefined>>,
    setEoiDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    columns?: Array<Column<Eoi>>,
) => {
    const classes = useStyles();

    const investigatorsContext = React.useContext(InvestigatorsContext);

    const onActionClick = React.useCallback((eoiId?: number, mode?: string) => {
        setEoidId(eoiId);
        setEoiDialogMode(mode);
        setEoiDialogOpen(true);
    }, [setEoidId, setEoiDialogMode, setEoiDialogOpen]);

    const eoiColumns = React.useMemo(() => {
        const actionColumns: Array<Column<Eoi>> = [];

        const defaultColumns: Array<Column<Eoi>> = [];

        if (canAdministerEConsent) {
            defaultColumns.push(
            //{
            //    field: 'id',
            //    title: 'Email Address',
            //    render: (eoi, type) => (eoi?.invitation?.emailAddress),
            //},
            {
                field: 'firstName',
                title: 'First Name',
                render: (eoi, type) => (eoi?.firstName),
                width: 50
            },
            {
                field: 'lastName',
                title: 'Last Name',
                render: (eoi, type) => (eoi?.lastName),
                width: 50
            });
        }
        else
        {
            defaultColumns.push(
                {
                    field: 'invitation.initials',
                    title: 'Initials',
                    render: (eoi, type) => (eoi?.invitation?.initials),
                    width: 50
                }
            );
        }

        defaultColumns.push({
            field: 'dateSubmitted',
            title: 'Date Submitted',
            render: (eoi, type) => (eoi?.dateSubmitted ? DateTime.fromISO(eoi.dateSubmitted).toFormat('dd/MM/yyyy, hh:mm a') : ''),
            width: 80
        });

        if (!!showInstitution) {
            defaultColumns.push({
                field: 'id',
                title: 'Institution',
                render: (eoi, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.code === (eoi?.invitation?.institutionCode))?.name}</div>),
                customSort: (data1, data2, type) => ((institutions.find(i => i.code === (data1?.invitation?.institutionCode)) as IInstitution).name) < ((institutions.find(i => i.code === (data2?.invitation?.institutionCode)) as IInstitution).name) ? -1 : 1,
            });
        }

        defaultColumns.push({
            field: 'id',
            title: 'Investigator',
            render: (eoi, type) => (<div className={classes.textWrap}>{investigatorsContext.investigators?.find(i => i.username === (eoi.invitation.investigator))?.name}</div>),
            customSort: (data1, data2, type) => ((investigatorsContext.investigators.find(i => i.username === (data1.invitation.investigator)) as any).name) < ((investigatorsContext.investigators!!.find(i => i.username === (data2.invitation.investigator)) as any).name) ? -1 : 1,
        });

        if (canAdministerEConsent) {
            actionColumns.push({
                field: 'id',
                title: 'Actions',
                render: eoi => {
                    return (
                        <>
                            <EoiButton
                                icon={faUserMd}
                                action={() => { onActionClick(eoi?.id, "face-to-face-consent") }}
                                tooltipTitle="Face-to-face Consent"
                            />
                            <EoiButton
                                icon={faLaptopHouse}
                                action={() => { onActionClick(eoi?.id, "remote-consent") }}
                                tooltipTitle="Remote Consent"
                            />
                            <EoiButton
                                icon={faFileMedicalAlt}
                                action={() => { onActionClick(eoi?.id, "standard-consent") }}
                                tooltipTitle="Standard Consent"
                            />
                            <EoiButton
                                icon={faTimes}
                                action={() => { onActionClick(eoi?.id, "not-interested") }}
                                tooltipTitle="Not Interested"
                            />
                        </>
                    );
                },
                width: 100,
                sorting: false
            });
        }

        return [...(defaultColumns ?? columns), ...actionColumns];
    }, [showInstitution, institutions, canAdministerEConsent, classes, onActionClick, columns, investigatorsContext])
    
    return eoiColumns;
};

const EoiButton: React.FunctionComponent<IEoiButtonProps> = ({
    icon,
    action,
    tooltipTitle,
    ...iconButtonProps
}) => {
    const classes = useStyles();

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    return <>
        {
            !!tooltipTitle && <LightTooltip title={tooltipTitle ?? ''} className={classes.tooltip}>
                <IconButton
                    color="primary"
                    className={classes.button}
                    size="medium"
                    onClick={action}
                >
                    <FontAwesomeIcon icon={icon} fixedWidth />
                </IconButton>
            </LightTooltip>
        }

        {
            !tooltipTitle && <IconButton
                color="primary"
                className={classes.button}
                onClick={action}
            >
                <FontAwesomeIcon icon={icon} fixedWidth />
            </IconButton>
        }
    </>
};

const defaultInstitutions: Array<IInstitution> = [];

const EoiCollapsibleTable = ({
    data,
    loading,
    title,
    columns,
    ...tableProps
}: IEoiCollapsibleTableProps) => {

    const { institutionCode } = useParams<Record<string, string>>();

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.filter(i => i.code).find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewEConsent, canAdministerEConsent], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null);

    const titleToUse = title ?? 'Expression of Interest';

    const [eois, eoisLoadState, eoisActions] = useEois(institution?.code, true);

    const { investigator, setInvestigator, investigators, setInvestigators } = React.useContext(InvestigatorsContext);

    const eoisToUse = React.useMemo(() => {
        if (!eois && !data) {
            return [];
        }

        return (
            data ??
            eois?.filter(e => e.statusId === StatusTypeEnum.Waiting &&
                (e.invitation.investigator == investigator?.username || (!investigator && investigators.find(i => e.invitation.investigator === i?.username)))
            ) ??
            []
        );
    }, [eois, data, investigator, investigators]);

    const eoisLoadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            eoisLoadState.state === RequestState.None || eoisLoadState.state === RequestState.Pending || permissionLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, eoisLoadState, permissionLoadState]);

    const [eoiDialogOpen, setEoiDialogOpen] = React.useState(false);

    const [eoiDialogMode, setEoiDialogMode] = React.useState<string | undefined>(undefined);

    const [eoiId, setEoiId] = React.useState<number | undefined>(undefined);

    const columnsToUse = useEoisColumns(
        canAdministerEConsent,
        institutions ?? defaultInstitutions,
        !institution,
        setEoiId,
        setEoiDialogMode,
        setEoiDialogOpen,
        columns
    );

    const [tableCount, setTableCount] = React.useState(1);

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [columnsToUse, eoisToUse]);

    return (
        <>
            {
                <CollapsibleTable
                    key={tableCount}
                    title={titleToUse}
                    entityName="EOI"
                    loading={eoisLoadingToUse}
                    data={eoisToUse}
                    columns={columnsToUse}
                    pluralizeTitle={false}
                    {...tableProps}
                />
            }

            {
                !!eoiId && !!eoiDialogMode && <EoiDialog
                    eoiId={eoiId!!}
                    mode={eoiDialogMode as "face-to-face-consent" | "remote-consent" | "standard-consent" | "not-interested" }
                    open={eoiDialogOpen}
                    setOpen={setEoiDialogOpen}
                />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default EoiCollapsibleTable;
