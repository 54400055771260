/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use invitations by institution codes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { InvitationsReducer, invitationsActions, useInvitationsSelector, invitationsSelectors, IInvitationsStore } from '../store/invitations';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseInvitationsActions {
    load: BoundActionCreator<OmitFirstArg<typeof invitationsActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof invitationsActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useInvitations = (institutionCode?: string, autoLoad?: boolean): [
    Array<Dtos.Invitation> | null,
    IRequestState<ResponseStatus>,
    IUseInvitationsActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => invitationsActions.load(institutionCode);
        load.type = invitationsActions.load.type;

        const clear = () => invitationsActions.clear(institutionCode);
        clear.type = invitationsActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [invitationsActions, institutionCode, dispatch]);

    const invitationSelector = React.useCallback((state: IInvitationsStore) => {
        return invitationsSelectors.invitations(state, institutionCode)
    }, [invitationsSelectors.invitations, institutionCode]);

    const loadStateSelector = React.useCallback((state: IInvitationsStore) => {
        return invitationsSelectors.loadState(state, institutionCode)
    }, [invitationsSelectors.loadState, institutionCode]);

    const invitations = useInvitationsSelector(invitationSelector);

    const loadState = useInvitationsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionCode]);

    return [
        invitations as Array<Dtos.Invitation> | null,
        loadState,
        actions
    ];
};

