/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the invitations reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualInvitationsState {
    invitations: Array<Dtos.Invitation> | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IInvitationsState {
    byContext: Record<string, IIndividualInvitationsState>;
};

export interface IInvitationsStore {
    invitations: IInvitationsState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualInvitationsState: IIndividualInvitationsState = {
    invitations: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialInvitationsState: IInvitationsState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class InvitationsReducer extends ImmerReducer<IInvitationsState> {
    public load(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualInvitationsState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(institutionCode?: string, invitations?: Array<Dtos.Invitation>) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualInvitationsState };
        }

        this.draftState.byContext[context].invitations = invitations ? invitations : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionCode?: string, responseStatus?: ResponseStatus) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualInvitationsState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialInvitationsState };
    }
};

export const invitationsActions = createActionCreators(InvitationsReducer);
export const invitationsReducer = createReducerFunction(InvitationsReducer, initialInvitationsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createInvitationsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.InvitationGetCollection());
    },
    loadByInstitutionCode: (institutionCode?: string) => {
        const request: Dtos.InvitationGetCollection = new Dtos.InvitationGetCollection({ institutionCode });

        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createInvitationsLogic = (api: ReturnType<typeof createInvitationsApi>) => {
    const logic = {
        load: createLogic<IInvitationsStore, {}, undefined, string, ReturnType<typeof invitationsActions.load>>({
            type: invitationsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const institutionCode = action.payload as unknown as string;

                try {
                    let response: Dtos.InvitationGetCollectionResponse | null = null;

                    if (institutionCode) {
                        response = await api.loadByInstitutionCode(institutionCode);
                    } else {
                        response = await api.load();
                    }

                    dispatch(invitationsActions.loadSuccess(
                        institutionCode,
                        response?.invitations
                    ));
                }
                catch (error: any) {
                    dispatch(invitationsActions.loadFailure(
                        institutionCode,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useInvitationsSelector: TypedUseSelectorHook<IInvitationsStore> = useSelector;

export const invitationsSelectors = {
    invitations: (state: IInvitationsStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.invitations?.byContext[context]?.invitations ?? initialIndividualInvitationsState.invitations;
    },
    loadState: (state: IInvitationsStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.invitations?.byContext[context]?.loadState ?? initialIndividualInvitationsState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerInvitationsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createInvitationsApi(client);

    const logic = createInvitationsLogic(api);

    reducerRegistry.register('invitations', invitationsReducer as Reducer, logic as any);
};

export default registerInvitationsReducer;