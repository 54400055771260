/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    OnlinePatientManagementLayout,
    IMenuItem,
    OnlinePatientManagementContext,
    InstitutionsByCodesRoute,
    RouteLoading,
    CrfFormByCodesRoutes,
    PatientSummaryByStudyNumberRoute,
    useIsMobile,
    useAuthenticatedUser,
    //UserType,
    ICrfFormMapping,
    UserType,
    InstitutionsByIdsResolver,
    PatientsByCodesResolver
} from '@ngt/opms';

import { EConsentPage } from '@ngt/opms-econsent'

import { usePermissionsByIds } from '@ngt/opms-bctapi'

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the sae icon.
 */
import { faFileMedical as saeIcon } from '@fortawesome/pro-duotone-svg-icons/faFileMedical';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

/**
 * Used for the econsent icon.
 */
import { faFileSignature as eConsentIcon } from '@fortawesome/pro-duotone-svg-icons/faFileSignature';

import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons';

import { Switch, Redirect, Route, useParams } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import * as Dtos from './api/dtos';

import Registration from './containers/Registration';

import PatientSummary from './containers/PatientSummary';

//import StatusForm from './containers/form/StatusForm';

import PatientTransfer from './containers/PatientTransfer';

import PatientEventRoute from './components/route/PatientEventRoute';

import SaeSummary from './containers/SaeSummary';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';
import { AppSettings } from './AppSettings';

const ParticipantForm = React.lazy(() => import('./containers/form/ParticipantForm'));


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

const patientSummaryMapping: Record<string, ICrfFormMapping> = {
    "participant-form": {
        components:
            <InstitutionsByIdsResolver
                resolveBeforeLoad={true}
            >
                <PatientSummary />
            </InstitutionsByIdsResolver>
    }
}

const formMapping: Record<string, ICrfFormMapping> = {
    "participant-form": {
        components:
            <InstitutionsByIdsResolver
                resolveBeforeLoad={true}
            >
                <ParticipantForm />
            </InstitutionsByIdsResolver>
    }
}

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAccess,
    Dtos.Permission.SaeView,
    Dtos.Permission.EConsentView,
    Dtos.Permission.SaeAdminister,
    Dtos.Permission.SaeReview,
    Dtos.Permission.MedicalReviewPerform,
    Dtos.Permission.EConsentAdminister,
    Dtos.Permission.EConsentManage,
    Dtos.Permission.ScreeningLogView,
    Dtos.Permission.ScreeningLogUpdate
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {
    const [user] = useAuthenticatedUser();

    const isPatient = user?.type === UserType.Patient;

    const [[canAccessOpms, canViewSae, canViewEConsent], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const menuItems: IMenuItem[] = React.useMemo(() => {

        if (isPatient) {
            return [];
        }

        let items: IMenuItem[] = [];

        //if (canViewEConsent) {
        //    items.push({
        //        url: '/econsent',
        //        label: 'eConsent',
        //        external: false,
        //        newTab: false,
        //        icon: eConsentIcon
        //    });
        //}

        items.push(
            {
                url: '/registration',
                label: 'Participants',
                external: false,
                newTab: false,
                icon: faUser as any
            }
        );

        if (canViewSae) {
            items.push({
                url: '/sae',
                label: 'SAE Summary',
                external: false,
                newTab: false,
                icon: saeIcon as any
            });
        }

        items.push(
            {
                url: AppSettings.redactUrl,
                label: 'Redact',
                external: true,
                newTab: true,
                icon: faEyeSlash as any
            }
        );

        return items;
    }, [isPatient, canViewEConsent, canViewSae]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const isMobile = useIsMobile();

    const hasPermission = (permissionLoadState.state === RequestState.Cancel ||
        permissionLoadState.state === RequestState.Success ||
        permissionLoadState.state === RequestState.Failure) &&
        (canAccessOpms || canViewSae || canViewEConsent)

    if ( permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <OnlinePatientManagementLayout
                menuItems={menuItems}
                footer={
                    isMobile ?
                        undefined :
                        <Typography variant="caption">
                            Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2025 ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
                        </Typography>
                }
            >
                <React.Suspense fallback={<RouteLoading />}>
                    {
                        hasPermission ?
                        <Switch>
                            <CrfFormByCodesRoutes
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-participant`
                                ]}
                                eventDefinitionCode="enrollment"
                                eventRepeat={1}
                                formDefinitionCode="participant-form"
                                formRepeat={1}
                                createPatient
                                resolveBeforeLoad
                                formMapping={formMapping}
                            />
                            <CrfFormByCodesRoutes
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                                ]}
                                resolveBeforeLoad
                                formMapping={formMapping}
                            />
                            <PatientEventRoute
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/transfer`
                                ]}
                                eventDefinitionCode="enrollment"
                                eventRepeat={1}
                                resolveBeforeLoad
                            >
                                <InstitutionsByIdsResolver
                                    resolveBeforeLoad={true}
                                >
                                    <PatientTransfer />
                                </InstitutionsByIdsResolver>
                            </PatientEventRoute>
                            <CrfFormByCodesRoutes
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                                ]}
                                eventDefinitionCode="enrollment"
                                eventRepeat={1}
                                formDefinitionCode="participant-form"
                                formRepeat={1}
                                resolveBeforeLoad
                                formMapping={patientSummaryMapping}
                            />
                            <InstitutionsByCodesRoute
                                path={[
                                    `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                    '/registration'
                                ]}
                            >
                                <Registration />
                            </InstitutionsByCodesRoute>
                            <InstitutionsByCodesRoute
                                path={[
                                    `/sae/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                    '/sae'
                                ]}
                            >
                                <SaeSummary />
                            </InstitutionsByCodesRoute>
                            <InstitutionsByCodesRoute
                                path={[
                                    `/econsent/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                    '/econsent'
                                ]}
                            >
                                <EConsentPage />
                            </InstitutionsByCodesRoute>
                            <Redirect path="*" to="/registration" />
                            <Redirect path="*" to="/registration/admin" />
                        </Switch> :
                        <ErrorPage errorType={ErrorTypeEnum.e403} />
                    }
                </React.Suspense>
            </OnlinePatientManagementLayout>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;
