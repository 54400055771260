/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use eois by institution codes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { EoisReducer, eoisActions, useEoisSelector, eoisSelectors, IEoisStore } from '../store/eois';
import { Eoi } from '../api/eoi';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseEoisActions {
    load: BoundActionCreator<OmitFirstArg<typeof eoisActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof eoisActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useEois = (institutionCode?: string, autoLoad?: boolean): [
    Array<Eoi> | null,
    IRequestState<ResponseStatus>,
    IUseEoisActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => eoisActions.load(institutionCode);
        load.type = eoisActions.load.type;

        const clear = () => eoisActions.clear(institutionCode);
        clear.type = eoisActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [eoisActions, institutionCode, dispatch]);

    const eoiSelector = React.useCallback((state: IEoisStore) => {
        return eoisSelectors.eois(state, institutionCode)
    }, [eoisSelectors.eois, institutionCode]);

    const loadStateSelector = React.useCallback((state: IEoisStore) => {
        return eoisSelectors.loadState(state, institutionCode)
    }, [eoisSelectors.loadState, institutionCode]);

    const eois = useEoisSelector(eoiSelector);

    const loadState = useEoisSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionCode]);

    return [
        eois as Array<Eoi> | null,
        loadState,
        actions
    ];
};

