/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the econsent landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { Theme, makeStyles, Grid, TextField, Select, Tooltip } from '@material-ui/core';

import {
    InstitutionsContext,
    TrialContextSelector,
    ALL_INSTITUTIONS_CODE,
    ProgressButton,
    OnlinePatientManagementContext,
    useSnackbar,
    useAuthenticatedUser,
    CodeSelector,
    InstitutionContext
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi'

import AlertTitle from '@material-ui/lab/AlertTitle';

import { DateTime } from 'luxon';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useInvitations, IUseInvitationsActions } from '../hooks/useInvitations';
import { InvestigatorsContext, IInvestigator} from './EConsentPage';
import { RequestState } from '@ngt/request-utilities';
import EConsentContext from '../context/EConsentContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInvitationFormProps {
}

interface IInviteProps {

}

interface IItem {
    id?: number;
    name?: string;
    code?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    inviteGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end',
    },
    inviteBtn: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),

        [theme.breakpoints.only('md')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },

        [theme.breakpoints.only('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        }

    },
    inviteEmail: {
        marginLeft: theme.spacing(1),
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.EConsentView,
    Permission.EConsentAdminister,
    Permission.EConsentManage,
];

const ALL_INVESTIGATORS = "All Investigators"

const ALL_INVESTIGATORS_CODE = "all-investigators"

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const Invite: React.FunctionComponent<IInviteProps> = () => {

    const classes = useStyles();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const { noInviteOption } = React.useContext(EConsentContext);

    const client = onlinePatientManagement?.serviceStackClient;

    const [user] = useAuthenticatedUser();

    const { enqueueSnackbar } = useSnackbar();

    const { institutionCode } = useParams<Record<string, string>>();

    const [inviteSending, setInviteSending] = React.useState(false);

    const [inviteEmail, setInviteEmail] = React.useState('');

    const [invitations, invitationsLoadState, invitationsActions] = useInvitations(institutionCode);

    const [selectedInvestigator, setSelectedInvestigator] = React.useState<string | undefined>(undefined);

    const handleInviteEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInviteEmail(event.target.value);
    }, [setInviteEmail]);

    const [investigatorItems, setInvestigatorItems] = React.useState<IItem[]>([]);
    const [investigatorLoading, setInvestigatorLoading] = React.useState<boolean>(false);

    const onClick = React.useCallback(() => {
        setInviteSending(true);

        client
            .post(new Dtos.InvitationSave({
                invitation: new Dtos.Invitation({
                    emailAddress: inviteEmail,
                    dateSent: DateTime.local().toUTC().toString(),
                    investigator: selectedInvestigator === ALL_INVESTIGATORS_CODE ? undefined : selectedInvestigator,
                    institutionCode: institutionCode,
                    statusId: Dtos.StatusTypeEnum.Waiting,
                })
            }))
            .then(response => {
                setInviteEmail('');
                setInviteSending(false);
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Invitation Sent
                        </AlertTitle>
                        The invitation was successfully sent.
                    </>,
                    { variant: 'success' }
                );
                invitationsActions.load();
            })
            .catch((e) => {
                setInviteSending(false);
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Invitation Not Sent
                        </AlertTitle>
                        {e.responseStatus.message}
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [institutionCode, invitationsActions, inviteEmail, selectedInvestigator, setInviteEmail, setInviteSending, enqueueSnackbar, client, user]);

    const { investigator, setInvestigator, investigators, setInvestigators } = React.useContext(InvestigatorsContext);

    // get the list of investigators based on the institutioncode
    React.useEffect(() => {
        setInvestigatorLoading(true);

        client
            .get(new Dtos.InvitationGetInvestigators({
                institutionCode: institutionCode
            }))
            .then(response => {
                setInvestigatorLoading(false);

                let items: IItem[] = [];
                let investigators: IInvestigator[] = [];

                Object.keys(response.investigators).forEach((key, index) => {

                    let item: IItem = {
                        id: index,
                        code: key,
                        name: response.investigators[key]
                    }

                    items.push(item);
                    investigators.push({ name: item.name, username: item.code })
                });

                setInvestigatorItems(items);
                setInvestigators(investigators);

                // set the investigator to the logged in user if the username is in the list of investigators, else all investigators
                const userInvestigator = investigators.find(i => i.username === user?.userName);

                if (userInvestigator) {
                    setSelectedInvestigator(userInvestigator.username);
                    setInvestigator({ name: userInvestigator.name, username: userInvestigator.username });
                } else if (!investigators.find(i => i.username == selectedInvestigator)){
                    setSelectedInvestigator(ALL_INVESTIGATORS_CODE);
                    setInvestigator(undefined);
                }
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Investigators Not Loaded
                        </AlertTitle>
                        An error occurred while trying to load the investigators.
                    </>,
                    { variant: 'critical' }
                );

                setInvestigatorLoading(false);
            });
    }, [client, institutionCode, setInvestigatorItems, setSelectedInvestigator, setInvestigatorLoading, user]);

    const onInvestigatorChange = React.useCallback((code?: string | null | undefined) => {
        const investigatorItem = investigatorItems.find(i => i.code === code);

        setSelectedInvestigator(investigatorItem?.code);

        investigatorItem ? setInvestigator({ name: investigatorItem.name, username: investigatorItem.code }) : setInvestigator(undefined);

    }, [investigatorItems, setSelectedInvestigator]);

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewEConsent, canAdministerEConsent, canManageEConsent], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id ?? null, null, institution ? true : false);

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                xs={12}
                sm={!noInviteOption ? 5 : 12}
            >
                <CodeSelector
                    inputId="investigator"
                    label="Investigators"
                    onChange={onInvestigatorChange}
                    selection={selectedInvestigator}
                    loading={investigatorLoading}
                    items={investigatorItems}
                    allSelectionAllowed={true}
                    allSelectionLabel={ALL_INVESTIGATORS}
                    allSelectionValue={ALL_INVESTIGATORS_CODE}
                />
            </Grid>
            {
                !noInviteOption && <>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        style={{ width: '100%' }}
                    >
                        <TextField
                            id="name"
                            value={inviteEmail}
                            onChange={handleInviteEmailChange}
                            label="Participant's Email Address"
                            type="email"
                            variant="standard"
                            style={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={2}
                        style={{ display: 'flex', alignItems: 'end' }}
                    >
                        <div style={{ overflow: 'hidden' }}>
                            {
                                !!canAdministerEConsent ?
                                    <ProgressButton
                                        variant="contained"
                                        color="primary"
                                        className={classes.inviteBtn}
                                        spacing={0}
                                        loading={inviteSending}
                                        onClick={() => { onClick() }}
                                        style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                    >
                                        Invite
                                    </ProgressButton>
                                    :
                                    <Tooltip
                                        title="Insufficient permissions to send an invitation"
                                        style={{ width: '100%' }}
                                    >
                                        <div>
                                            <ProgressButton
                                                variant="contained"
                                                color="primary"
                                                className={classes.inviteBtn}
                                                spacing={0}
                                                loading={inviteSending}
                                                onClick={() => { onClick() }}
                                                style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                                disabled
                                            >
                                                Invite
                                            </ProgressButton>
                                        </div>
                                    </Tooltip>
                            }

                        </div>
                    </Grid>
                </>
            }
        </Grid>
    );
}

const InvitationForm: React.FunctionComponent<IInvitationFormProps> = () => {

    const classes = useStyles();

    const history = useHistory();

    const { noInviteOption } = React.useContext(EConsentContext);

    const { institutionCode } = useParams<Record<string, string>>();

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/econsent/${newInstitutionCode}`);
        }
        else {
            history?.push(`/econsent`);
        }

        return;
    }, [history]);

    const institutionsToUse = React.useMemo(() => {
        return institutions?.filter(i => i.code && i.eConsentActivationDate);
    }, [institutions]);

    return (
        <>
            <Grid item xs={12}>
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={false}
                    allowAllCollaboratingGroups={false}
                    allowAllCountries={false}
                    allowAllInstitutions={true}
                    hideMasterGroups={true}
                    hideCollaboratingGroups={true}
                    hideCountries={true}
                    masterGroupCode={null}
                    collaboratingGroupCode={null}
                    countryCode={null}
                    institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                    institutions={institutionsToUse}
                    sm={!noInviteOption ? 4 : 6}
                >
                    <Grid
                        item
                        xs={12}
                        sm={!noInviteOption ? 8 : 6}
                        className={classes.inviteGrid}
                    >
                        <Invite />
                    </Grid>
                </TrialContextSelector>
            </Grid>
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default InvitationForm;

