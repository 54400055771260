/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the patient consent dialog component
 * --------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { Button, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Grid, Link, Select, MenuItem } from '@material-ui/core';

import { DateTime } from 'luxon';

import {
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
    FileUpload,
    useAuthenticatedUser
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { StatusTypeEnum, ConsentTypeEnum } from '../api/dtos';
import { useParams } from 'react-router-dom';
import { usePatientConsent } from '../hooks/usePatientConsent';
import { usePatientConsents } from '../hooks/usePatientConsents';
import { PatientConsent } from '../api/patientConsent';
import EConsentContext from '../context/EConsentContext';
import { KeyboardDatePicker } from '@material-ui/pickers';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPatientConsentDialogProps {
    patientConsentId: number;
    mode: "restart-consent" | "send-reminder" | "sign" | "refused-consent" | "verify-consent";
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitle: {
        color: theme.palette.primary.main
    },
    dialogContent: {
        padding: theme.spacing(0, 3),
        overflow: 'visible'
    },
    link: {
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const PatientConsentDialog: React.FunctionComponent<IPatientConsentDialogProps> = ({
    patientConsentId,
    mode,
    open,
    setOpen
}: IPatientConsentDialogProps) => {
    const classes = useStyles();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement?.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextEConsent = React.useContext(EConsentContext);

    const [patientConsents, patientConsentsLoadState, patientConsentsActions] = usePatientConsents(institutionCode);

    const [patientConsent, patientConsentActions, patientConsentLoadState] = usePatientConsent(patientConsentId, true);

    const [picfs, setPicfs] = React.useState<Dtos.Picf[]>([]);

    const [actionLoading, setActionLoading] = React.useState(false);

    const [fileUpload, setFileUpload] = React.useState<Dtos.ConsentFileUpload>();

    const [consentType, setConsentType] = React.useState<number>();

    React.useEffect(() => {
        setConsentType(patientConsent?.typeId);
    }, [patientConsent, setConsentType]);

    const [error, setError] = React.useState<string>();

    const dialogTitle = React.useMemo(() => {
        switch (mode) {
            case "send-reminder": { return "Send Reminder"; break; }
            case "restart-consent": { return "Restart Consent"; break; }
            case "refused-consent": { return "Refused Consent"; break; }
            case "verify-consent": { return "Verify Consent"; break; }
        }
    }, [mode]);

    const dialogText: string = React.useMemo(() => {
        switch (mode) {
            case "send-reminder": {
                if (patientConsent?.statusId === Dtos.StatusTypeEnum.PatientSigning) {
                    return `Send the participant a reminder. The participant will receive an email to sign the consent form.`;
                } else {
                    return `Send the investigator a reminder. The investigator will receive an email to sign the consent form.`;
                }
                
                break;
            }
            case "sign": {
                return ``;
                break;
            }
            case "restart-consent": {
                return `Restart the consent process. `;
                break;
            }
            case "refused-consent": {
                return `The participant refused to participate in the study.`;
                break;
            }
            case "verify-consent": {
                return `Verify the uploaded consent form. The file will be deleted once verified.`;
                break;
            }
        }
    }, [mode, patientConsent]);

    const onRefusedConsentClick = React.useCallback(() => {

        setActionLoading(true);

        client
            .post(new Dtos.PatientConsentSaveStatus({
                id: patientConsent!.id,
                statusId: StatusTypeEnum.Refused
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Saved
                        </AlertTitle>
                        Changes were saved successfully.
                    </>,
                    { variant: 'success' }
                );
                patientConsentsActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Saved
                        </AlertTitle>
                        An error occurred while trying to save the changes.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, patientConsent, patientConsentsActions, enqueueSnackbar, setOpen, setActionLoading]);

    const [standardConsentDate, setStandardConsentDate] = React.useState<DateTime | null>(null);

    const onRestartConsentClick = React.useCallback(() => {

        setActionLoading(true);

        const updatedConsent = {
            id: patientConsent?.id,
            eoiId: patientConsent?.eoi?.id,
            typeId: consentType,
            statusId: consentType === ConsentTypeEnum.Standard ? StatusTypeEnum.Completed : StatusTypeEnum.PatientSigning,
            guid: patientConsent?.guid,
            dateStarted: DateTime.local().toUTC().toString(),
            dateNotificationSent: consentType === ConsentTypeEnum.Remote ? DateTime.local().toUTC().toString() : null,
            patientSigned: consentType === ConsentTypeEnum.Standard ? true : false,
            datePatientSigned: consentType === ConsentTypeEnum.Standard ? standardConsentDate?.toUTC().toString() : false,
            investigatorSigned: consentType === ConsentTypeEnum.Standard ? true : false,
            dateInvestigatorSigned: consentType === ConsentTypeEnum.Standard ? standardConsentDate?.toUTC().toString() : false,
            picfs: consentType === ConsentTypeEnum.Standard ?
                picfs.map(picf => {
                    var consentPicf = new Dtos.ConsentPicf();

                    consentPicf.picfTypeId = picf.type.id;
                    consentPicf.picfId = picf.picfId;

                    return consentPicf;
                })
                :
                null
        };

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: updatedConsent as any as PatientConsent,
                emailRequired: consentType === ConsentTypeEnum.Remote || consentType === ConsentTypeEnum.Standard ? true : false
            }))
            .then(response => {
                if (response.patientConsent?.typeId === ConsentTypeEnum.FaceToFace) {
                    window.location.assign(contextEConsent.trialConsentPageUrl + patientConsent?.guid);
                } else {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Saved
                            </AlertTitle>
                            Changes were saved successfully.
                        </>,
                        { variant: 'success' }
                    );
                    patientConsentsActions.load();
                    setActionLoading(false);
                    setOpen(false);
                    setFileUpload(undefined);
                    setConsentType(response.patientConsent?.typeId);
                    setError(undefined);
                    setStandardConsentDate(null);
                }
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Saved
                        </AlertTitle>
                        An error occurred while trying to save the changes.
                    </>,
                    { variant: 'critical' }
                );

                setActionLoading(false);

                if (consentType === ConsentTypeEnum.Standard) {
                    setError(e.responseStatus.message);
                    setFileUpload(undefined);
                    setStandardConsentDate(null);
                } else {
                    setOpen(false);
                }
            })
    }, [client, enqueueSnackbar, patientConsent, picfs, patientConsentsActions, setOpen, setActionLoading, contextEConsent, fileUpload, setError, setActionLoading, setFileUpload, consentType, standardConsentDate, setStandardConsentDate]);

    const onSendReminderClick = React.useCallback(() => {
        client
            .post(new Dtos.PatientConsentSendReminder({
                id: patientConsent?.id
            }))
            .then(response => {  
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Email Sent
                    </AlertTitle>
                    Email was sent successfully.
                    </>,
                    { variant: 'success' }
                );
                patientConsentsActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Saved
                        </AlertTitle>
                        An error occurred while trying to send the email.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, enqueueSnackbar, patientConsent, setOpen, setActionLoading]);

    const viewConsentFileLink = React.useMemo(() => {
        return `/opms/patient-consent/view-consent-file/${patientConsent?.id}`;
    }, [patientConsent]);

    const onVerifyConsentClick = React.useCallback(() => {

        setActionLoading(true);

        const updatedConsent: PatientConsent = Object.assign({}, patientConsent as PatientConsent);

        updatedConsent.documentVerified = true;
        updatedConsent.dateDocumentVerified = DateTime.local().toUTC().toString(),
        updatedConsent.statusId = StatusTypeEnum.Completed;

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: updatedConsent as PatientConsent,
                emailRequired: false
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Verified
                        </AlertTitle>
                        Consent form was verified successfully.
                    </>,
                    { variant: 'success' }
                );
                patientConsentsActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Verified
                        </AlertTitle>
                        An error occurred while trying to verify the consent form.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, patientConsent, patientConsentsActions, enqueueSnackbar, setOpen, setActionLoading, contextEConsent]);

    const picfDownloadLink = `/opms/picf/download/all/${patientConsent?.eoi?.invitation?.institutionCode}`;

    const onPicfDownloadClick = React.useCallback(() => {
        setActionLoading(true);

        client
            .get(new Dtos.GetDownloadPicfs({
                instCode: patientConsent?.eoi?.invitation?.institutionCode
            }))
            .then(response => {
                var link = document.createElement('a');
                link.href = picfDownloadLink
                link.click();
                link.remove();

                setActionLoading(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while trying to download the PICFs.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setFileUpload(undefined);
            });
    }, [client, patientConsent, setActionLoading, picfDownloadLink, enqueueSnackbar, setFileUpload]);

    React.useEffect(() => {
        if (patientConsent?.eoi?.invitation?.institutionCode) {
            client
                .get(new Dtos.GetPicfs({
                    instCode: patientConsent?.eoi?.invitation?.institutionCode
                }))
                .then(response => {
                    setPicfs(response.picfs)
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            There is a problem with the site PICFs. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                });
        }
    }, [client, enqueueSnackbar, patientConsent, setPicfs]);

    return (
        <>
            {
                !!patientConsent && <Dialog
                    open={open}
                    onClose={() => { setOpen(false); setConsentType(patientConsent?.typeId); setFileUpload(undefined); setError(undefined); }}
                    aria-labelledby="patient-consent-dialog"
                    maxWidth="sm" fullWidth
                >
                    <DialogTitle
                        id="patient-consent-dialog"
                        className={classes.dialogTitle}
                    >
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText>
                            {dialogText}
                            {
                                mode === "restart-consent" && consentType === Dtos.ConsentTypeEnum.Remote && (
                                    <> The participant will receive an email to complete the consent form again. </>
                                )
                            }
                            {
                                mode === "restart-consent" && consentType === Dtos.ConsentTypeEnum.FaceToFace && (
                                    <> The participant will have to complete the consent form again. </>
                                )
                            }
                            {
                                mode === "restart-consent" && consentType === Dtos.ConsentTypeEnum.Standard && (
                                    <>
                                        Download the consent form <Link className={classes.link} variant="body1" onClick={onPicfDownloadClick}>here</Link>.
                                    </>
                                )
                            }
                        
                            {
                                mode === "restart-consent" && (
                                    <div style={{ marginTop: '12px', marginBottom: '12px' }}>
                                        <Select
                                            value={consentType}
                                            onChange={(event) => { setConsentType(event.target.value as number) }}
                                            fullWidth
                                        >
                                            <MenuItem value={1}>Remote Consent</MenuItem>
                                            <MenuItem value={2}>Face-to-face</MenuItem>
                                            <MenuItem value={3}>Standard</MenuItem>
                                        </Select>
                                    </div>
                                )
                            }
                            {
                                mode === "restart-consent" && consentType === Dtos.ConsentTypeEnum.Standard && (
                                    <Grid
                                        container
                                        style={{ marginTop: "1rem" }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Typography style={{ paddingTop: '0.5rem' }}>Date consent completed</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                value={standardConsentDate}
                                                onChange={(date) => setStandardConsentDate(date)}
                                                clearable
                                                fullWidth
                                            />
                                            {
                                                error && (
                                                    <DialogContentText style={{ color: '#f44336', fontSize: '0.8rem', paddingTop: '0.5rem' }}>
                                                        {error}
                                                    </DialogContentText>
                                                )
                                            }


                                        </Grid>
                                        <div style={{ marginTop: '1.5rem' }}>Please ensure signed PICF has been filed in ISF for viewing at onsite monitoring.</div>
                                    </Grid>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ProgressButton
                            loading={actionLoading}
                            color="secondary"
                            onClick={() => { setOpen(false); setConsentType(patientConsent?.typeId); setFileUpload(undefined); setError(undefined); }}
                        >
                            Cancel
                        </ProgressButton>
                        {
                            mode === "send-reminder" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onSendReminderClick()}
                                disabled={false}
                            >
                                {dialogTitle}
                            </ProgressButton>
                        }

                        {
                            mode === "restart-consent" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onRestartConsentClick()}
                                disabled={false}
                            >
                                {dialogTitle}
                            </ProgressButton>
                        }

                        {
                            mode === "refused-consent" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={() => onRefusedConsentClick()}
                                disabled={false}
                            >
                                {dialogTitle}
                            </ProgressButton>
                        }

                        {
                            mode === "verify-consent" && <>
                                <ProgressButton
                                    loading={actionLoading}
                                    color="primary"
                                    onClick={() => window.open(viewConsentFileLink, "_blank", "noopener,noreferrer")}
                                    disabled={false}
                                >
                                    View
                                </ProgressButton>
                                <ProgressButton
                                    loading={actionLoading}
                                    color="primary"
                                    onClick={() => onVerifyConsentClick()}
                                    disabled={false}
                                >
                                    Verify
                                </ProgressButton>
                            </>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default PatientConsentDialog;