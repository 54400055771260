/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an form by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { FormsReducer, useFormsSelector, formsSelectors, IFormsStore } from '../../store/modules/data/forms';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstThreeArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * Used to get online patient management settings from context.
 */
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFormsByCodesActions {
    load: BoundActionCreator<OmitFirstThreeArgs<ActionCreators<typeof FormsReducer>['loadByCodes']>>;
    clear: BoundActionCreator<OmitFirstThreeArgs<ActionCreators<typeof FormsReducer>['clearByCodes']>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFormsByCodes = <FormType extends Dtos.IForm>(
    formDefinitionIdentifier: string | number,
    patientStudyNumber?: string | null,
    eventDefinitionCode?: string | null,
    eventRepeat?: number | null,
    autoLoad?: boolean
): [
    FormType[] | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseFormsByCodesActions
] => {
    const dispatch = useDispatch();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const formPropertyName = React.useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return onlinePatientManagement.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier)?.propertyName;
        }

        return onlinePatientManagement.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier)?.propertyName;
    }, [onlinePatientManagement.formMetadata, formDefinitionIdentifier]);

    if (!formPropertyName) {
        throw new Error("Form Metadata not found for provided Form Definition Codeentifier.");
    }

    const unboundActions = React.useMemo(() => {
        return onlinePatientManagement.formsReducerRegistry.getActions(formPropertyName);
    }, [onlinePatientManagement.formsReducerRegistry, formPropertyName]);

    if (!unboundActions) {
        throw new Error("Forms Reducer not found for provided Form Definition Codeentifier.");
    }

    const actions: IUseFormsByCodesActions = React.useMemo(() => {
        const load = () => unboundActions.loadByCodes(patientStudyNumber, eventDefinitionCode, eventRepeat);
        load.type = unboundActions.loadByCodes.type;

        const clear = () => unboundActions.clearByCodes(patientStudyNumber, eventDefinitionCode, eventRepeat);
        clear.type = unboundActions.clearByCodes.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [unboundActions, patientStudyNumber, eventDefinitionCode, eventRepeat, dispatch]);

    const formSelector = React.useCallback((state: IFormsStore) => {
        return formsSelectors.formsByCodes(state, formPropertyName, patientStudyNumber, eventDefinitionCode, eventRepeat);
    }, [formsSelectors.formsByCodes, formPropertyName, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    const loadStateSelector = React.useCallback((state: IFormsStore) => {
        return formsSelectors.loadStateByCodes(state, formPropertyName, patientStudyNumber, eventDefinitionCode, eventRepeat);
    }, [formsSelectors.loadStateByCodes, formPropertyName, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    const forms = useFormsSelector(formSelector);

    const loadState = useFormsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    return [
        forms as FormType[] | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFormsByCodes;