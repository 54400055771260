/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { makeStyles, Theme } from '@material-ui/core/styles';

import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';
import { IInputRenderProps } from '../../../form/components/Input';
import FormLookupContext from '../../../contexts/form/FormLookupContext';
import SelectIcon from '../../selector/SelectIcon';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type SelectProps = Partial<MuiSelectProps> & IInputRenderProps<number, Dtos.IValidationError>

export interface ISelectProps extends SelectProps {
    nullOption?: boolean;
}

interface ISelectIconProps {
    className?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    text: {
    }
}));

const useIconStyles = makeStyles<Theme>(theme => ({
    icon: {
        top: 'calc(50% - 8px)'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Select: React.FunctionComponent<ISelectProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    onChange,
    children,
    fullWidth,
    nullOption,
    inputProps,
    ...rest
}) => {
    const lookup = React.useContext(FormLookupContext);

    const onChangeCombined = React.useCallback((event: React.ChangeEvent<{ name: string | undefined; value: number; }>, child: React.ReactNode) => {
        if (onChange) {
            onChange(event, child);
        }

        onInputChange(event.target.value != null ? event.target.value : undefined as any);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
    }, [inputProps?.onBlur, onBlur]);

    return (
        <MuiSelect
            {...rest as any}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            name={name}
            value={value ?? ''}
            IconComponent={SelectIcon}
            onChange={onChangeCombined}
        >
            {
                (nullOption !== false) && (
                    <MenuItem
                        value={undefined}
                    >
                        &nbsp;
                    </MenuItem>
                )
            }
            {
                children ?? lookup?.items?.map(item => {
                    return (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                        >
                            {item.value}
                        </MenuItem>
                    );
                })
            }
        </MuiSelect>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Select;
