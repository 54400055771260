/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to get access to router context.
 */
import { useHistory, useParams, Link } from 'react-router-dom';

/*
 * Used to style components
 */
import { makeStyles, Grid, Button, Tooltip, Paper } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    TrialContextSelector,
    ALL_MASTER_GROUPS_CODE,
    ALL_COLLABORATING_GROUPS_CODE,
    ALL_COUNTRIES_CODE,
    ALL_INSTITUTIONS_CODE,

    InstitutionsContext,
    PatientsByCodesResolver,
    PatientCollapsibleTable,
    CountriesContext,
    InstitutionBreadcrumbs,
    PatientsContext,
    CollapsibleTable,
    useSnackbar,
    MasterGroupContext,
    CollaboratingGroupContext,
    CountryContext,
    usePatientsByCodes,
    PageLayout
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * used to get column spec for the pre-registered patient table.
 */
import usePatientColumns from '../hooks/usePatientColumns';

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { usePermissionsByIds } from '@ngt/opms-bctapi';
import { RequestState } from '@ngt/request-utilities';
import { AlertTitle } from '@material-ui/lab';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IRegistrationParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IRegistrationProps {

}

interface IPatientTableProps {
    showInstitution: boolean,
    onRowClick?: (event: any, rowData: any) => void
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    addPatientColumn: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    addPatient: {
        width: '100%'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister
];

const patientStates = [Dtos.PatientStateType.Enrolled];

const defaultMasterGroupCode = null;
const defaultCollaboratingGroupCode = null;
const defaultCountryCode = null;

const PatientTable: React.FunctionComponent<IPatientTableProps> = ({
    showInstitution,
    onRowClick
}) => {
    const { institutions } = React.useContext(InstitutionsContext);

    const patientColumns = usePatientColumns(institutions, showInstitution);

    const [tableCount, setTableCount] = React.useState(1);

    const { patients: contextPatients, loadState: patientsLoadState } = React.useContext(PatientsContext);

    const patientsToUse: Dtos.Patient[] = contextPatients as Dtos.Patient[];

    const patientsLoadingToUse = patientsLoadState.state === RequestState.None || patientsLoadState.state === RequestState.Pending;

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [patientsToUse, patientColumns]);

    return (
        <CollapsibleTable
            key={tableCount}
            data={patientsToUse ?? []}
            title="Participants"
            loading={patientsLoadingToUse}
            entityName="Participant"
            columns={patientColumns}
            onRowClick={onRowClick as any}
            options={{
                pageSize: 20,
                pageSizeOptions: [20]
            }}
        />
    );
}

const Registration: React.FunctionComponent<IRegistrationProps> = () => {
    const classes = useStyles();

    const history = useHistory();
    
    const { enqueueSnackbar } = useSnackbar();

    const { masterGroupCode, collaboratingGroupCode, countryCode, institutionCode } = useParams<IRegistrationParams>();

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history.push(`/registration/${newInstitutionCode}`);
        }
        else {
            history.push(`/registration`);
        }

        return;
    }, [history]);

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(institution => institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions]);

    const [[canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, true);

    const institutionsToUse = React.useMemo(() => {
        return institutions?.filter(i => i.code);
    }, [institutions]);

    const showInstitutions = React.useMemo(() => {
        return !parsedInstitutionCode
    }, [parsedInstitutionCode]);

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { country } = React.useContext(CountryContext);

    const [patients] = usePatientsByCodes(masterGroup?.code, collaboratingGroup?.code, country?.code, institution?.code, patientStates, true);



    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IPatient | undefined) => {
        
        const patient = patients?.find(p => p.studyNumber === rowData?.studyNumber);
        const institutionChecker = institutions?.find(i => i.id === patient?.institutionId)

        if (institutionChecker?.enabled == true)
        {
        history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}`)
        }
        else
        {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Error
                    </AlertTitle>
                    The institution this patient is connected to is no longer available.
                </>,
                { variant: 'critical' }
            );

        }
    }, [history, institutionCode, patients, institutions]);


    return (
        <PageLayout
            breadcrumbs={<InstitutionBreadcrumbs />}
            heading="Registration"
        >
            <Paper
                className={classes.container}
            >
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={true}
                    allowAllCollaboratingGroups={true}
                    allowAllCountries={true}
                    allowAllInstitutions={true}
                    hideMasterGroups={true}
                    hideCollaboratingGroups={true}
                    hideCountries={true}
                    masterGroupCode={masterGroupCode}
                    collaboratingGroupCode={collaboratingGroupCode}
                    countryCode={countryCode}
                    institutionCode={institutionCode}
                    institutions={institutionsToUse}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.addPatientColumn}
                    >
                        {
                            parsedInstitutionCode && canAdminsterOpms ?
                                institution?.enabled ?
                                    <Button
                                        className={classes.addPatient}
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}
                                    >
                                        Add Participant
                                    </Button> :
                                    <Tooltip
                                        className={classes.addPatient}
                                        title="Institution must be active to add a participant"
                                    >
                                        <div>
                                            <Button
                                                className={classes.addPatient}
                                                disabled
                                                variant="contained"
                                                color="primary"
                                                component={Link}
                                                to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}
                                            >
                                                Add Participant
                                            </Button>
                                        </div>
                                    </Tooltip> :
                                <Tooltip
                                    className={classes.addPatient}
                                    title={canAdminsterOpms ? 'Select an institution to add a participant' : 'Insufficient permissions to add a participant'}
                                >
                                    <div>
                                        <Button
                                            className={classes.addPatient}
                                            disabled
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={`/registration/${parsedInstitutionCode ?? ALL_INSTITUTIONS_CODE}/add-participant`}
                                        >
                                            Add Participant
                                        </Button>
                                    </div>
                                </Tooltip>
                        }
                    </Grid>
                </TrialContextSelector>

                <PatientsByCodesResolver
                    masterGroupCode={defaultMasterGroupCode}
                    collaboratingGroupCode={defaultCollaboratingGroupCode}
                    countryCode={defaultCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={patientStates}
                >
                    <PatientTable
                        showInstitution={showInstitutions ?? true}
                        onRowClick={onRowClick}
                    />
                </PatientsByCodesResolver>
            </Paper>
        </PageLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Registration;
