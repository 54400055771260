/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the invitation reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualInvitationState {
    invitation: Dtos.Invitation | null;
    loadState: IRequestState<ResponseStatus>; 
};

export interface IInvitationState {
    byContext: Record<string, IIndividualInvitationState>;
};

export interface IInvitationStore {
    invitation: IInvitationState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualInvitationState: IIndividualInvitationState = {
    invitation: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialInvitationState: IInvitationState = {
    byContext: {}
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdContext = (id?: number | null) => {
    return `${(id ?? 'new')}`;
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class InvitationReducer extends ImmerReducer<IInvitationState> {
    public load(id: number) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualInvitationState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(id: number, invitation?: Dtos.Invitation) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualInvitationState };
        }

        this.draftState.byContext[context].invitation = invitation ?? null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(id: number, responseStatus?: ResponseStatus) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualInvitationState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(id?: number) {
        const context = createIdContext(id);

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialInvitationState };
    }
};

export const invitationActions = createActionCreators(InvitationReducer);
export const invitationReducer = createReducerFunction(InvitationReducer, initialInvitationState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createInvitationApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        const request: Dtos.InvitationGetSingleById = new Dtos.InvitationGetSingleById({
            id
        });
        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createInvitationLogic = (api: ReturnType<typeof createInvitationApi>) => {
    const logic = {
        load: createLogic<IInvitationStore, {}, undefined, string, ReturnType<typeof invitationActions.load>>({
            type: invitationActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload as unknown as number;

                try {
                    let response: Dtos.InvitationGetSingleByIdResponse | null = null;

                    response = await api.load(id);

                    dispatch(invitationActions.loadSuccess(
                        id,
                        response.invitation
                    ));
                }
                catch (error: any) {
                    dispatch(invitationActions.loadFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
    }

    return [
        logic.load,
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useInvitationSelector: TypedUseSelectorHook<IInvitationStore> = useSelector;

export const invitationSelectors = {
    invitation: (state: IInvitationStore, id?: number) => {
        const context = createIdContext(id);

        return state.invitation?.byContext[context]?.invitation ?? initialIndividualInvitationState.invitation;
    },
    loadState: (state: IInvitationStore, id?: number) => {
        const context = createIdContext(id);

        return state.invitation?.byContext[context]?.loadState ?? initialIndividualInvitationState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerInvitationReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createInvitationApi(client);

    const logic = createInvitationLogic(api);

    reducerRegistry.register('invitation', invitationReducer as Reducer, logic as any);
};

export default registerInvitationReducer;