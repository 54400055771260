/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an institutions by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { InstitutionsReducer, institutionsActions, useInstitutionsSelector, institutionsSelectors, IInstitutionsStore } from '../../store/modules/data/institutions';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg, OmitFirstThreeArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseInstitutionsByIdActions {
    load: BoundActionCreator<OmitFirstThreeArgs<typeof institutionsActions.loadByIds>>;
    clear: BoundActionCreator<OmitFirstThreeArgs<typeof institutionsActions.clearByIds>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useInstitutionsByIds = <InstitutionsType extends Dtos.IInstitution>(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, autoLoad?: boolean): [
    InstitutionsType[] | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseInstitutionsByIdActions
] => {
    const dispatch = useDispatch();

    const actions: IUseInstitutionsByIdActions = React.useMemo(() => {
        const load = () => institutionsActions.loadByIds(masterGroupId, collaboratingGroupId, countryId);
        load.type = institutionsActions.loadByIds.type;

        const clear = () => institutionsActions.clearByIds(masterGroupId, collaboratingGroupId, countryId);
        clear.type = institutionsActions.clearByIds.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [institutionsActions, masterGroupId, collaboratingGroupId, countryId, dispatch]);

    const institutionsSelector = React.useCallback((state: IInstitutionsStore) => {
        return institutionsSelectors.institutionsByIds(state, masterGroupId, collaboratingGroupId, countryId);
    }, [institutionsSelectors.institutionsByIds, masterGroupId, collaboratingGroupId, countryId]);

    const loadStateSelector = React.useCallback((state: IInstitutionsStore) => {
        return institutionsSelectors.loadStateByIds(state, masterGroupId, collaboratingGroupId, countryId);
    }, [institutionsSelectors.loadStateByIds, masterGroupId, collaboratingGroupId, countryId]);

    const institutions = useInstitutionsSelector(institutionsSelector);

    const loadState = useInstitutionsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, masterGroupId, collaboratingGroupId, countryId]);

    return [
        institutions as InstitutionsType[] | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useInstitutionsByIds;