/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use patient columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution
} from '@ngt/opms';

import { Chip, makeStyles } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import FormContext from '@ngt/opms/dist/form/contexts/FormContext';
import { getStatus } from './useStatusColumns';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    chip: {
        backgroundColor: '#987ba7',
        color: theme.palette.common.white,
    },

}));

const usePatientColumns = (institutions: IInstitution[] | null, showSite: boolean): Column<Dtos.Patient>[] => {
    const classes = useStyles();
    
    const columns = React.useMemo(() => {
        const c: (Column<Dtos.Patient> | boolean)[] = [
            {
                field: 'studyNumber',
                title: 'Participant Number',
                width: 155
            },
            {
                field: 'dateEnrolled',
                title: 'Date Enrolled',
                render: rowData => (
                    rowData.dateEnrolled ? DateTime.fromISO(rowData.dateEnrolled).toFormat('dd/MM/yyyy') : null
                ),
                width: 210
            },
            showSite && {
                field: 'institutionCode',
                title: 'Site Code',
                render: rowData => (
                    <Chip
                        label={rowData.institutionCode}
                        className={classes.chip}
                        size="small"
                    />
                ),
                width: 125
            },
            showSite && {
                field: 'institutionId',
                title: 'Institution',
                render: rowData => (
                    institutions?.find(i => i.id === rowData.institutionId)?.name
                )
            },
            {
                field: 'patientStateId',
                title: 'Status',
                render: rowData => (
                    getStatus(rowData.status)
                ),
                width: 210
            }
        ];

        return c.filter(column => !!column) as Column<Dtos.Patient>[];
    }, [showSite, institutions]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default usePatientColumns;