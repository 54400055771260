/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass form context to the rest of the app.
 */
import FormsContext from '../../../contexts/data/FormsContext';

/*
 * Used to load collaborating groups
 */
import useFormsByCodes from '../../../hooks/data/useFormsByCodes';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormsByCodesResolverProps {
    formDefinitionIdentifier: string | number;
    patientStudyNumber?: string | null,
    eventDefinitionCode?: string | null,
    eventRepeat?: number | null,

    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const FormsByCodesResolver: React.FunctionComponent<IFormsByCodesResolverProps> = ({
    formDefinitionIdentifier,
    patientStudyNumber,
    eventDefinitionCode,
    eventRepeat,
    resolveBeforeLoad,
    children
}) => {
    const [forms, loadState, actions] = useFormsByCodes(formDefinitionIdentifier, patientStudyNumber, eventDefinitionCode, eventRepeat, true);

    const context = React.useMemo(() => {
        return {
            forms,
            loadState,
            actions
        };
    }, [forms, loadState, actions]);

    return (
        <FormsContext.Provider value={context}>
            {
                !resolveBeforeLoad || (loadState.state !== RequestState.None && loadState.state !== RequestState.Pending) ?
                    children :
                    <RouteLoading />
            }
        </FormsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormsByCodesResolver;