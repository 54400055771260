/* Options:
Date: 2023-02-07 11:58:39
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44367

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: NewtonGreen.ServiceStack.Opms.EConsent/*,__EConsent.Eoi/*,__EConsent.PatientConsent/*
//ExcludeTypes: 
DefaultImports: IGet:@ngt/opms,IPost:@ngt/opms,IDelete:@ngt/opms,IModel:@ngt/opms,IDataModel:@ngt/opms,IFileUploadTemporary:@ngt/opms,Eoi:./eoi,PatientConsent:./patientConsent,ResponseError:@ngt/opms,ResponseStatus:@ngt/opms,IReturn:@ngt/opms,IValidationError:@ngt/opms,IValidationResult:@ngt/opms,ValidationResultType:@ngt/opms,ValidationErrorType:@ngt/opms
*/

import { IGet } from "@ngt/opms";
import { IPost } from "@ngt/opms";
import { IDelete } from "@ngt/opms";
import { IModel } from "@ngt/opms";
import { IDataModel } from "@ngt/opms";
import { IFileUploadTemporary } from "@ngt/opms";
import { Eoi } from "./eoi";
import { PatientConsent } from "./patientConsent";
import { ResponseError } from "@ngt/opms";
import { ResponseStatus } from "@ngt/opms";
import { IReturn } from "@ngt/opms";
import { IValidationError } from "@ngt/opms";
import { IValidationResult } from "@ngt/opms";
import { ValidationResultType } from "@ngt/opms";
import { ValidationErrorType } from "@ngt/opms";

export enum StatusTypeEnum
{
    Waiting = 1,
    Responded = 2,
    NotInterested = 3,
    PatientSigning = 4,
    InvestigatorSigning = 5,
    VerifyingDocument = 6,
    Completed = 7,
    Refused = 8,
}

export class StatusType
{
    public id: StatusTypeEnum;
    public value: string;

    public constructor(init?: Partial<StatusType>) { (Object as any).assign(this, init); }
}

export class Invitation
{
    public id?: number;
    // @Ignore()
    public initials: string;

    public emailAddress: string;
    public institutionCode: string;
    public investigator: string;
    public guid: string;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.StatusType)")
    public statusId?: StatusTypeEnum;

    public dateSent?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public status: StatusType;

    public constructor(init?: Partial<Invitation>) { (Object as any).assign(this, init); }
}

export interface IEoiGetSingleById extends IGet
{
    id?: number;
}

export interface IEoiGetCollection extends IGet
{
}

export interface IEoiGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface IEoiDelete extends IDelete
{
    id?: number;
}

export interface IPatientConsentGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientConsentGetSingleByGuid extends IGet
{
    guid: string;
}

export interface IPatientConsentGetCollection extends IGet
{
}

export interface IPatientConsentGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface IPatientConsentDelete extends IDelete
{
    id?: number;
}

export class PicfType
{
    public id?: number;
    public name: string;
    public label: string;
    public fileName: string;

    public constructor(init?: Partial<PicfType>) { (Object as any).assign(this, init); }
}

export class Picf
{
    public type: PicfType;
    public picfId?: number;
    public name: string;
    public label: string;
    public version: string;
    public fileName: string;
    public file: string;

    public constructor(init?: Partial<Picf>) { (Object as any).assign(this, init); }
}

export interface IEoi extends IDataModel
{
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    invitationId?: number;

    firstName: string;
    lastName: string;
    confirmed?: boolean;
    dateSubmitted?: string;
    statusId?: StatusTypeEnum;
    invitation: Invitation;
}

export enum ConsentTypeEnum
{
    Remote = 1,
    FaceToFace = 2,
    Standard = 3,
}

export class ConsentPicf
{
    public id?: number;
    public consentId?: number;
    public picfTypeId?: number;
    public picfId?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConsentPicf>) { (Object as any).assign(this, init); }
}

export class ConsentFileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<ConsentFileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class ConsentFileUpload
{
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public exists: boolean;
    public image: boolean;
    public upload: ConsentFileUploadTemporary;

    public constructor(init?: Partial<ConsentFileUpload>) { (Object as any).assign(this, init); }
}

export class ConsentType
{
    public id: ConsentTypeEnum;
    public value: string;

    public constructor(init?: Partial<ConsentType>) { (Object as any).assign(this, init); }
}

export interface IPatientConsent extends IDataModel
{
    eoiId?: number;
    typeId?: ConsentTypeEnum;
    statusId?: StatusTypeEnum;
    guid: string;
    dateStarted?: string;
    mobileNumber: string;
    patientSigned?: boolean;
    datePatientSigned?: string;
    investigatorSigned?: boolean;
    dateInvestigatorSigned?: string;
    documentVerified?: boolean;
    documentVerifiedBy: string;
    dateDocumentVerified?: string;
    dateNotificationSent?: string;
    documentUpload: ConsentFileUpload;
    type: ConsentType;
    status: StatusType;
    eoi: IEoi;
    picfs: ConsentPicf[];
}

export class InvitationGetCollectionResponse
{
    public responseStatus: ResponseStatus;
    public invitations: Invitation[];

    public constructor(init?: Partial<InvitationGetCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByIdResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByIdResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByGuidResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByGuidResponse>) { (Object as any).assign(this, init); }
}

export class InvitationSaveResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSaveResponse>) { (Object as any).assign(this, init); }
}

export class InvitationDeleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<InvitationDeleteResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetInvestigatorsResponse
{
    public responseStatus: ResponseStatus;
    public investigators: { [index: string]: string; };

    public constructor(init?: Partial<InvitationGetInvestigatorsResponse>) { (Object as any).assign(this, init); }
}

export class GetPicfsResponse
{
    public responseStatus: ResponseStatus;
    public picfs: Picf[];

    public constructor(init?: Partial<GetPicfsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eoi.
    */
    // @DataMember(Order=2)
    public eoi: Eoi;

    public constructor(init?: Partial<EoiSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eois.
    */
    // @DataMember(Order=2)
    public eois: Eoi[];

    public constructor(init?: Partial<EoiCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EoiDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patientconsent.
    */
    // @DataMember(Order=2)
    public patientConsent: PatientConsent;

    public constructor(init?: Partial<PatientConsentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patientconsents.
    */
    // @DataMember(Order=2)
    public patientConsents: PatientConsent[];

    public constructor(init?: Partial<PatientConsentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientConsentDeleteResponse>) { (Object as any).assign(this, init); }
}

export class PatientConsentSendVerificationCodeResponse
{
    public responseStatus: ResponseStatus;
    public verificationCode: number;

    public constructor(init?: Partial<PatientConsentSendVerificationCodeResponse>) { (Object as any).assign(this, init); }
}

// @Route("/opms/invitation/collection", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetCollection implements IReturn<InvitationGetCollectionResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetCollectionResponse(); }
}

// @Route("/opms/invitation/single/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleById implements IReturn<InvitationGetSingleByIdResponse>, IGet
{
    public id?: number;

    public constructor(init?: Partial<InvitationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByIdResponse(); }
}

// @Route("/opms/invitation/single/{Guid}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleByGuid implements IReturn<InvitationGetSingleByGuidResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<InvitationGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByGuidResponse(); }
}

// @Route("/opms/invitation/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationSave implements IReturn<InvitationSaveResponse>, IPost
{
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvitationSaveResponse(); }
}

// @Route("/opms/invitation/delete/{Id}", "DELETE")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationDelete implements IReturn<InvitationDeleteResponse>, IDelete
{
    public id?: number;

    public constructor(init?: Partial<InvitationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new InvitationDeleteResponse(); }
}

// @Route("/opms/invitation/investigators", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetInvestigators implements IReturn<InvitationGetInvestigatorsResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetInvestigators>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetInvestigators'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetInvestigatorsResponse(); }
}

// @Route("/opms/picf/{InstCode}/{PatientConsentId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetPicfs implements IReturn<GetPicfsResponse>, IGet
{
    public instCode: string;
    public patientConsentId?: number;

    public constructor(init?: Partial<GetPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPicfsResponse(); }
}

// @Route("/opms/picf/download/all/{InstCode}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicfs implements IReturn<Blob>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetDownloadPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/picf/download/{InstCode}/{PicfId}/{PicfTypeId}/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicf implements IReturn<Blob>, IGet
{
    public instCode: string;
    public picfId?: number;
    public picfTypeId?: number;

    public constructor(init?: Partial<GetDownloadPicf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/single/id/{Id}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiGetSingleById implements IReturn<EoiSingleResponse>, IEoiGetSingleById
{
    /**
    * The ID of the eoi to retrieve.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiSingleResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/collection", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollection implements IReturn<EoiCollectionResponse>, IEoiGetCollection
{

    public constructor(init?: Partial<EoiGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollectionByInstitutionCode implements IReturn<EoiCollectionResponse>, IEoiGetCollectionByInstitutionCode
{
    /**
    * The institution code of the eoi to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<EoiGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/save", "POST")
// @Api(Description="Eoi")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiPostSave implements IReturn<EoiSingleResponse>
{
    /**
    * The eoi to save.
    */
    // @DataMember(Order=1)
    public eoi: Eoi;

    /**
    * The eoi to save.
    */
    // @DataMember(Order=2)
    public invitationGuid: string;

    public constructor(init?: Partial<EoiPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EoiSingleResponse(); }
}

/**
* Eoi
*/
// @Route("/opms/eoi/delete/{Id}", "DELETE")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=500)
// @DataContract
export class EoiDelete implements IReturn<EoiDeleteResponse>, IEoiDelete
{
    /**
    * The eoi ID to delete.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EoiDeleteResponse(); }
}

// @Route("/opms/eoi/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class EoiSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<EoiSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/single/id/{Id}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleById implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleById
{
    /**
    * The ID of the patientconsent to retrieve.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/single/guid/{Guid}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleByGuid implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleByGuid
{
    /**
    * The guid of the patientconsent to retrieve.
    */
    // @DataMember(Order=1)
    public guid: string;

    public constructor(init?: Partial<PatientConsentGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/collection", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollection implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollection
{

    public constructor(init?: Partial<PatientConsentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollectionByInstitutionCode implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollectionByInstitutionCode
{
    /**
    * The institution code of the patientconsent to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<PatientConsentGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/save", "POST")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentPostSave implements IReturn<PatientConsentSingleResponse>
{
    /**
    * The patientconsent to save.
    */
    // @DataMember(Order=1)
    public patientConsent: PatientConsent;

    /**
    * The patientconsent to check.
    */
    // @DataMember(Order=1)
    public emailRequired: boolean;

    public constructor(init?: Partial<PatientConsentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

/**
* PatientConsent
*/
// @Route("/opms/patientconsent/delete/{Id}", "DELETE")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientConsentDelete implements IReturn<PatientConsentDeleteResponse>, IPatientConsentDelete
{
    /**
    * The patientconsent ID to delete.
    */
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientConsentDeleteResponse(); }
}

// @Route("/opms/patient-consent/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<PatientConsentSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-reminder", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendReminder implements IReturn<ResponseStatus>, IPost
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentSendReminder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendReminder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-verification-code", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendVerificationCode implements IReturn<PatientConsentSendVerificationCodeResponse>, IPost
{
    public phoneNumber: string;

    public constructor(init?: Partial<PatientConsentSendVerificationCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendVerificationCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSendVerificationCodeResponse(); }
}

// @Route("/opms/patient-consent/view-consent-file/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentViewConsentFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentViewConsentFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentViewConsentFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

