/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the econsent landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams } from 'react-router-dom';

import { Paper, Theme, makeStyles } from '@material-ui/core';

import {
    InstitutionsContext,
    InstitutionBreadcrumbs,
    PageLayout,
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi'

import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import InvitationForm from './InvitationForm';
import InvitationCollapsibleTable from './InvitationCollapsibleTable';
import EoiCollapsibleTable from './EoiCollapsibleTable';
import RefusedConsentCollapsibleTable from './RefusedConsentCollapsibleTable';
import PatientConsentCollapsibleTable from './PatientConsentCollapsibleTable';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IEConsentPageProps {
}

export interface IInvestigator {
    name?: string;
    username?: string;
}

interface IInvestigatorsContext {
    investigator: IInvestigator | undefined;
    setInvestigator: (investigator: IInvestigator | undefined) => void;
    investigators: IInvestigator[];
    setInvestigators: (investigators: IInvestigator[]) => void;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

//const permissions: Permission[] = [
//    Permission.EConsentView
//];

export const InvestigatorsContext = React.createContext<IInvestigatorsContext>({
    investigator: undefined,
    setInvestigator: () => { },
    investigators: [],
    setInvestigators: () => { },
});

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const EConsentPage: React.FunctionComponent<IEConsentPageProps> = () => {

    const classes = useStyles();

    const [investigator, setInvestigator] = React.useState<IInvestigator | undefined>(undefined);

    const [investigators, setInvestigators] = React.useState<IInvestigator[]>([]);

    const investigatorsContext: IInvestigatorsContext = React.useMemo(() => {
        return {
            investigator,
            setInvestigator: (investigator) => setInvestigator(investigator),
            investigators,
            setInvestigators: (investigators) => setInvestigators(investigators)
        }

    }, [investigator, setInvestigator, investigators, setInvestigators]);

    return (
        <PageLayout
            breadcrumbs={<InstitutionBreadcrumbs />}
            heading="eConsent"
        >
            <Paper
                className={classes.container}
            >
                <InvestigatorsContext.Provider value={investigatorsContext}>
                    <InvitationForm />
                    <InvitationCollapsibleTable />
                    <EoiCollapsibleTable />
                    <PatientConsentCollapsibleTable />
                    <RefusedConsentCollapsibleTable />
                </InvestigatorsContext.Provider>
            </Paper>
        </PageLayout>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default EConsentPage;

