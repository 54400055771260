/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the eoi dialog component
 * --------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { Button, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

import { DateTime } from 'luxon';

import {
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { useEoi } from '../hooks/useEoi';
import { StatusTypeEnum, ConsentTypeEnum } from '../api/dtos';
import { useEois } from '../hooks/useEois';
import { useParams } from 'react-router-dom';
import { usePatientConsent } from '../hooks/usePatientConsent';
import { usePatientConsents } from '../hooks/usePatientConsents';
import { PatientConsent } from '../api/patientConsent';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IRefusedConsentDialogProps {
    refusedConsentId: number;
    type: "eoi" | "consent";
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitle: {
        color: theme.palette.primary.main
    },
    dialogContent: {
        padding: theme.spacing(0, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const RefusedConsentDialog: React.FunctionComponent<IRefusedConsentDialogProps> = ({
    refusedConsentId,
    type,
    open,
    setOpen
}: IRefusedConsentDialogProps) => {
    const classes = useStyles();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement?.serviceStackClient;

    const { enqueueSnackbar } = useSnackbar();

    const { institutionCode } = useParams<Record<string, string>>();

    const [eois, eoisLoadState, eoisActions] = useEois(institutionCode);

    const [eoi, eoiActions, eoiLoadState] = useEoi(refusedConsentId, type === "eoi" ? true : false);

    const [patientConsent, patientConsentActions, patientConsentLoadState] = usePatientConsent(refusedConsentId, type === "consent" ? true : false);

    const [patientConsents, patientConsentsLoadState, patientConsentsActions] = usePatientConsents(institutionCode);

    const [actionLoading, setActionLoading] = React.useState(false);

    const dialogTitle = React.useMemo(() => {
        switch (type) {
            case "eoi": {
                return "Reset Expression of Interest";
                break;
            }
            case "consent": {
                return "Reset Patient Consent";
                break;
            }
        }
    }, [type]);

    const dialogText: string = React.useMemo(() => {
        switch (type) {
            case "eoi": {
                return `Reset the expression of interest back to its previous status.`;
                break;
            }
            case "consent": {
                return `Reset the consent back to its previous status.`;
                break;
            }
        }
    }, [type, eoi, patientConsent]);

    const onEoiResetClick = React.useCallback(() => {
        if (!eoi) {
            return;
        }

        setActionLoading(true);

        client
            .post(new Dtos.EoiSaveStatus({
                id: eoi.id,
                statusId: Dtos.StatusTypeEnum.Waiting
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Saved
                        </AlertTitle>
                        Changes were successfully saved.
                    </>,
                    { variant: 'success' }
                );
                eoisActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Saved
                        </AlertTitle>
                        An error occurred while trying to save the changes.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, eoi, eoisActions, enqueueSnackbar, setOpen, setActionLoading, classes]);

    const onConsentRefusedClick = React.useCallback(() => {
        if (!patientConsent) {
            return;
        }

        setActionLoading(true);

        const updatedConsent = {
            id: patientConsent?.id,
            eoiId: patientConsent?.eoi?.id,
            typeId: patientConsent?.typeId,
            statusId: StatusTypeEnum.PatientSigning,
            dateStarted: patientConsent?.dateStarted,
            dateNotificationSent: patientConsent?.typeId === ConsentTypeEnum.Remote ? DateTime.local().toUTC().toString() : null,
            guid: patientConsent?.guid
        }

        client
            .post(new Dtos.PatientConsentPostSave({
                patientConsent: updatedConsent as PatientConsent,
                emailRequired: true
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Saved
                        </AlertTitle>
                        Changes were successfully saved.
                    </>,
                    { variant: 'success' }
                );
                patientConsentsActions.load();
                setActionLoading(false);
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Not Saved
                        </AlertTitle>
                        An error occurred while trying to save the changes.
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, patientConsent, patientConsentsActions, enqueueSnackbar, setOpen, setActionLoading, classes]);

    return (
        <>
            {
                ((!!eoi && type === "eoi") || (!!patientConsent && type === "consent")) && <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="refused-consent-dialog" maxWidth="sm">
                    <DialogTitle
                        id="refused-consent-dialog-title"
                        className={classes.dialogTitle}
                    >
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText>
                            {dialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ProgressButton
                            loading={actionLoading}
                            color="secondary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </ProgressButton>
                        {
                            type === "eoi" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={onEoiResetClick}
                                disabled={false}
                            >
                                Reset
                            </ProgressButton>
                        }

                        {
                            type === "consent" && <ProgressButton
                                loading={actionLoading}
                                color="primary"
                                onClick={onConsentRefusedClick}
                                disabled={false}
                            >
                                Reset
                            </ProgressButton>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default RefusedConsentDialog;