/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the invitation dialog component
 * --------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { Button, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

import { DateTime } from 'luxon';

import {
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
    useAuthenticatedUser,
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { useInvitation } from '../hooks/useInvitation';
import { StatusTypeEnum } from '../api/dtos';
import { useInvitations, IUseInvitationsActions } from '../hooks/useInvitations';
import { useParams } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInvitationDialogProps {
    invitationId: number;
    mode: "resend" | "cancel";
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitle: {
        color: theme.palette.primary.main
    },
    dialogContent: {
        padding: theme.spacing(0, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const InvitationDialog: React.FunctionComponent<IInvitationDialogProps> = ({
    invitationId,
    mode,
    open,
    setOpen
}: IInvitationDialogProps) => {
    const classes = useStyles();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement?.serviceStackClient;

    const [user] = useAuthenticatedUser();

    const { enqueueSnackbar } = useSnackbar();

    const { institutionCode } = useParams<Record<string, string>>();

    const [invitations, invitationsLoadState, invitationsActions] = useInvitations(institutionCode);

    const [invitation, invitationLoadState, invitationActions] = useInvitation(invitationId, true);

    const [actionLoading, setActionLoading] = React.useState(false);

    const dialogTitle = React.useMemo(() => {
        switch (mode) {
            case "resend": { return "Resend Invitation"; break; }
            case "cancel": { return "Cancel Invitation"; break; }
        }
    }, [mode]);

    const onResendClick = React.useCallback(() => {
        if (!invitation || !user) {
            return;
        }

        const updatedInvitation = new Dtos.Invitation(invitation);

        setActionLoading(true);
        updatedInvitation.dateSent = DateTime.local().toUTC().toString();

        client
            .post(new Dtos.InvitationSave({
                invitation: updatedInvitation
            }))
            .then(response => {
                setActionLoading(false);
                setOpen(false);
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Invitation Sent
                        </AlertTitle>
                        The invitation was sent successfully.
                    </>,
                    { variant: 'success' }
                );
                invitationsActions.load();
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Invitation Not Sent
                        </AlertTitle>
                        {e.responseStatus.message}
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, enqueueSnackbar, invitation, invitationsActions, setOpen, setActionLoading, user]);

    const onCancelClick = React.useCallback(() => {
        if (!invitation) {
            return;
        }

        setActionLoading(true);

        client
            .delete(new Dtos.InvitationDelete({
                id: invitation.id
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Invitation Cancelled
                        </AlertTitle>
                        The invitation was cancelled successfully.
                    </>,
                    { variant: 'success' }
                );
                setActionLoading(false);
                setOpen(false);
                invitationsActions.load();
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Invitation Not Cancelled
                        </AlertTitle>
                        {e.responseStatus.message}
                    </>,
                    { variant: 'critical' }
                );
                setActionLoading(false);
                setOpen(false);
            })
    }, [client, enqueueSnackbar, invitation, invitationsActions, setOpen, setActionLoading, user]);

    return (
        <>
            {
                !!invitation && (
                    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="invitation-dialog" maxWidth="sm">
                        <DialogTitle
                            id="invitation-dialog-title"
                            className={classes.dialogTitle}
                        >
                            {dialogTitle}
                        </DialogTitle>
                        <DialogContent
                            className={classes.dialogContent}
                        >
                            <DialogContentText>
                                {mode === 'resend' ? 'Resend' : 'Cancel'} the invitation that was sent to <strong>{invitation?.emailAddress} </strong>on<strong> {invitation?.dateSent ? DateTime.fromISO(invitation?.dateSent).toFormat('dd/MM/yyyy, hh:mm a') : ''}</strong>.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ProgressButton
                                loading={actionLoading}
                                color="secondary"
                                onClick={() => setOpen(false)}
                            >
                                {mode === 'resend' ? 'Cancel' : 'Do not Cancel'}
                        </ProgressButton>
                            {
                                mode === "resend" && <ProgressButton
                                    loading={actionLoading}
                                    color="primary"
                                    onClick={() => onResendClick()}
                                    disabled={false}
                                >
                                    {dialogTitle}
                                </ProgressButton>
                            }

                            {
                                mode === "cancel" && <ProgressButton
                                    loading={actionLoading}
                                    color="primary"
                                    onClick={() => onCancelClick()}
                                    disabled={false}
                                >
                                    {dialogTitle}
                                </ProgressButton>
                            }
                        </DialogActions>
                    </Dialog>    
                )
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default InvitationDialog;