/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the patient consent reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { PatientConsent } from '../api/patientConsent';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualPatientConsentState {
    patientConsent: PatientConsent | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IPatientConsentState {
    byContext: Record<string, IIndividualPatientConsentState>;
};

export interface IPatientConsentStore {
    patientConsent: IPatientConsentState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualPatientConsentState: IIndividualPatientConsentState = {
    patientConsent: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialPatientConsentState: IPatientConsentState = {
    byContext: {}
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdContext = (id?: number | null) => {
    return `${(id ?? 'new')}`;
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class PatientConsentReducer extends ImmerReducer<IPatientConsentState> {
    public load(id: number) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualPatientConsentState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(id: number, patientConsent?: PatientConsent) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualPatientConsentState };
        }

        this.draftState.byContext[context].patientConsent = patientConsent ?? null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(id: number, responseStatus?: ResponseStatus) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualPatientConsentState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(id: number) {
        const context = `${id}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialPatientConsentState };
    }
};

export const patientConsentActions = createActionCreators(PatientConsentReducer);
export const patientConsentReducer = createReducerFunction(PatientConsentReducer, initialPatientConsentState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createPatientConsentApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        const request: Dtos.PatientConsentGetSingleById = new Dtos.PatientConsentGetSingleById({
            id
        });
        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createPatientConsentLogic = (api: ReturnType<typeof createPatientConsentApi>) => {
    const logic = {
        load: createLogic<IPatientConsentStore, {}, undefined, string, ReturnType<typeof patientConsentActions.load>>({
            type: patientConsentActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload as unknown as number;

                try {
                    let response: Dtos.PatientConsentSingleResponse | null = null;

                    response = await api.load(id);

                    dispatch(patientConsentActions.loadSuccess(
                        id,
                        response.patientConsent
                    ));
                }
                catch (error: any) {
                    dispatch(patientConsentActions.loadFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load,
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const usePatientConsentSelector: TypedUseSelectorHook<IPatientConsentStore> = useSelector;

export const patientConsentSelectors = {
    patientConsent: (state: IPatientConsentStore, id?: number) => {
        const context = `${id}`;

        return state.patientConsent?.byContext[context]?.patientConsent ?? initialIndividualPatientConsentState.patientConsent;
    },
    loadState: (state: IPatientConsentStore, id?: number) => {
        const context = `${id}`;

        return state.patientConsent?.byContext[context]?.loadState ?? initialIndividualPatientConsentState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPatientConsentReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientConsentApi(client);

    const logic = createPatientConsentLogic(api);

    reducerRegistry.register('patientConsent', patientConsentReducer as Reducer, logic as any);
};

export default registerPatientConsentReducer;