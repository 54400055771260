/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use invitation by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus,
    useAsyncFunction,
    bindActionCreatorsWithType
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { InvitationReducer, invitationActions, useInvitationSelector, invitationSelectors, IInvitationStore } from '../store/invitation';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseInvitationActions {
    load: BoundActionCreator<OmitFirstArg<typeof invitationActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof invitationActions.clear>>;
}
/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useInvitation = (id?: number, autoLoad?: boolean): [
    Dtos.Invitation | null,
    IRequestState<ResponseStatus>,
    IUseInvitationActions
] => {
    const dispatch = useDispatch();

    const actions: IUseInvitationActions = React.useMemo(() => {
        const load = () => invitationActions.load(id!!);
        load.type = invitationActions.load.type;

        const clear = () => invitationActions.clear(id);
        clear.type = invitationActions.clear.type;

        return bindActionCreators({
                load,
                clear,
            }, dispatch);
    }, [invitationActions, id, dispatch]);

    const invitationSelector = React.useCallback((state: IInvitationStore) => {
        return invitationSelectors.invitation(state, id)
    }, [invitationSelectors.invitation, id]);

    const loadStateSelector = React.useCallback((state: IInvitationStore) => {
        return invitationSelectors.loadState(state, id)
    }, [invitationSelectors.loadState, id]);

    const invitation = useInvitationSelector(invitationSelector);

    const loadState = useInvitationSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad && id) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, id]);

    return [
        invitation as Dtos.Invitation | null,
        loadState,
        actions
    ];
};

